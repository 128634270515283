import React from 'react';
import { Input, Button, Select, Textarea, PaperClip, Table, Tooltip } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { CampaignService } from 'services';
import {
	CampaignUserIcon,
	CampaignListIcon,
	CampaignTitleIcon,
	UserListIcon,
	TrashIcon
} from 'assets/icons';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalCreate extends React.Component<Props> {

	state = {
		form: {
			content: '',
			name: '',
			folder_id: ''
		},
		to: [],
		folders: [],
		header: [
			'Nombre',
			'Teléfono',
			'Acción'
		]
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await CampaignService.getFolders();
		this.setState({
			folders: res.folders || []
		});
	}

	submit = async () => {
		if (this.state.to.length == 0) {
			Globals.showError("Debe indicar al menos 1 destinatario");
			return;
		}

		await CampaignService.send({
			...this.state.form,
			to: JSON.stringify(this.state.to.map((i: any) => i.phone)),
			content: this.state.form.name + ': ' + this.state.form.content
		});
		Globals.showSuccess("Se ha enviado la campaña correctamente");
		this.props.onClose();
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	readExcel = async (file: any) => {
		const res: any = await CampaignService.readExcel({
			file: file.value,
			hasFile: true
		});
		this.setState({
			to: res.to || [],
			form: {
				...this.state.form,
				folder_id: ''
			}
		});
	}

	loadFolder = async () => {
		if (this.state.form.folder_id) {
			const res: any = await CampaignService.loadFolder({
				folder_id: this.state.form.folder_id
			});
			this.setState({
				to: res.contacts || []
			});
		}
		else {
			this.setState({
				to: []
			});
		}
	}

	delete = async (e: any, index: number) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar el destinatario?',async () => {
			let to = [...this.state.to];
			to.splice(index,1);
			this.setState({
				to
			});
		});
	}
	
	render() {
		const folder: any = this.state.folders.find((i: any) => i.id == this.state.form.folder_id);
		const characters = (this.state.form.name + (this.state.form.name && ': ') + this.state.form.content).length;

		return (
			<div id="modal-create-campaign">
				<div className="container-white">
					<div className="container-title">
						<div className="container-icon">
							<img src={ CampaignTitleIcon } />
						</div>
						<p>Remitente</p>
					</div>
					<p className="description">El nombre que ven tus destinatarios cuando reciben tu mensaje</p>
					<Input
						value={ this.state.form.name }
						name="name"
						placeholder="Escribir"
						onChange={ this.change } />
				</div>
				<div className="container-white">
					<div className="container-title">
						<div className="container-icon">
							<img src={ CampaignUserIcon } />
						</div>
						<p>Destinatarios</p>
					</div>
					<p className="description">Selecionar carpeta o subir contactos</p>
					<div className="container-upload">
						<Select
							color="white"
							placeholder="Seleccionar carpeta"
							name="folder_id"
							onChange={ (e: any) => this.change(e,() => {
								this.loadFolder();
							}) }
							value={ this.state.form.folder_id }
							options={ this.state.folders.map((item: any) => {
								return {
									value: item.id,
									label: item.name
								}
							}) } />
						<PaperClip
							successText={ `Subir contactos (Excel)` }
							pendingText={ `Subir contactos (Excel)` }
							icon={ UserListIcon }
							formats={ ['xls','xlsx'] }
							formatError="El archivo debe ser un excel válido"
							onChange={ (e: any) => {
								this.readExcel(e.target);
							} } />
					</div>
					<p className="note">Nota: En caso de subir un Excel, el sistema leera solo los valores de la columna A</p>
					{
						this.state.to.length > 0 && (
							<>
								<p className="to">{ this.state.to.length } Destinatarios</p>
								<Table title="Contactos" data={ this.state.to.length } header={ this.state.header } rightAlign={ [2] }>
									{ this.state.to.map((i: any,index: number) => {
										return (
											<tr key={ index }>
												<td>{ i.name || '-' }</td>
												<td>{ i.phone }</td>
												<td style={{ 'whiteSpace': 'nowrap', textAlign: 'right' }}>
													<Tooltip title="Eliminar">
														<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
															<img src={ TrashIcon } />
														</a>
													</Tooltip>
												</td>
											</tr>
										)
									}) }
								</Table>
							</>
						)
					}
				</div>
				<div className="container-white">
					<div className="container-title">
						<div className="container-icon">
							<img src={ CampaignListIcon } />
						</div>
						<p>Contenido del mensaje</p>
					</div>
					<Textarea
						label="Redactar el SMS"
						rows={ 4 }
						color="white"
						value={ this.state.form.name + (this.state.form.name && ': ') + this.state.form.content }
						name="content"
						placeholder="Escribir"
						onChange={ (e: any) => {
							let content = e.target.value;
							const value = this.state.form.name + (this.state.form.name && ': ');
							content = content.substr(value.length);

							this.setState({
								form: {
									...this.state.form,
									content
								}
							});
						} } />
					<div className="container-characters">
						<p className="characters-count"><strong>Caracteres:</strong> { characters }</p>
						<p className="characters-count"><strong>Cantidad de Mensajes:</strong> { Math.ceil(characters / Constants.MAX_CHARACTERS) }</p>
					</div>
				</div>
				<div className="text-center">
					<button className="btn btn-active" onClick={ () => this.submit() }>
						Enviar
					</button>
				</div>
			</div>
		)
	}
}

export default ModalCreate;