import React from 'react';
import { Input, Button, Select, DatePicker } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { ReportService } from 'services';
import moment from 'moment';

interface Props {
	onClose?: any;
	item?: any;
}

class CreatePayment extends React.Component<Props> {

	state = {
		form: {
			to_user_id: '',
			payment_method_id: '',
			currency_id: '',
			amount: '',
			plan_user_id: '',
			date: ''
		},
		payment_methods: [],
		currencies: [],
		users: [],
		plan_users: []
	}

	componentDidMount() {
		if (this.props.item) {
			const item: any = this.props.item;

			this.setState({
				form: {
					...this.state.form,
					to_user_id: item?.user_id || '',
					payment_method_id: item?.payment_method_id || '',
					currency_id: item?.currency_id || '',
					amount: item?.amount || '',
					plan_user_id: item?.plan_user_id || '',
					date: item?.date ? moment(item?.date).toDate() : ''
				}
			},() => {
				this.loadPendingPayments(true);
			});
		}

		this.loadPaymentMethods();
	}

	loadPaymentMethods = async () => {
		const res: any = await ReportService.admin.getPaymentMethods({
			withoutLoading: true
		});
		this.setState({
			payment_methods: res.payment_methods || [],
			currencies: res.currencies || [],
			users: res.users || []
		});
	}

	submit = async (e: any) => {
		e.preventDefault();

		if (this.props.item) {
			await ReportService.admin.createPayment({
				...this.state.form,
				payment_id: this.props.item?.id
			});
			this.props.onClose();
			Globals.showSuccess("Se ha modificado el pago correctamente");
		}
		else {
			await ReportService.admin.createPayment({
				...this.state.form
			});
			this.props.onClose();
			Globals.showSuccess("Se ha registrado el pago correctamente");
		}
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	loadPendingPayments = async (withoutLoading = false) => {
		const res: any = await ReportService.admin.loadPendingPayments({
			to_user_id: this.state.form.to_user_id,
			withoutLoading,
			payment_id: this.props.item?.id
		});
		this.setState({
			plan_users: res.plan_users || []
		},() => {
			if (res.plan_users.length == 0) {
				Globals.showError("El usuario no posee planes por pagar");
			}
		});
	}
	
	render() {
		return (
			<div id="modal-create-payment">
				<form onSubmit={ this.submit }>
					<div className="row">
						<div className="col-md-6">
							<Select
								color="white"
								placeholder="Seleccionar"
								label="Usuario"
								name="to_user_id"
								onChange={ (e: any) => this.change(e,() => {
									this.setState({
										form: {
											...this.state.form,
											plan_user_id: ''
										}
									},() => this.loadPendingPayments());
								}) }
								value={ this.state.form.to_user_id }
								options={ this.state.users?.map((i: any) => {
									return {
										value: i.id,
										label: i.name
									}
								}) || [] } />
						</div>
						{
							this.state.form.to_user_id && (
								<div className="col-md-6">
									<Select
										color="white"
										placeholder="Seleccionar"
										label="Plan a pagar"
										name="plan_user_id"
										onChange={ (e: any) => this.change(e,() => {
											const plan_user: any = this.state.plan_users.find((i: any) => i.id == this.state.form.plan_user_id);
											this.setState({
												form: {
													...this.state.form,
													amount: plan_user?.plan?.price || ''
												}
											});
										}) }
										value={ this.state.form.plan_user_id }
										options={ this.state.plan_users?.map((i: any) => {
											return {
												value: i.id,
												label: i.plan?.name + ' - ' + moment(i.expiration_date).format('DD/MM/YYYY')
											}
										}) || [] } />
								</div>
							)
						}
						{
							this.state.form.to_user_id && this.state.form.plan_user_id && (
								<>
									<div className="col-md-6">
										<Select
											color="white"
											placeholder="Seleccionar"
											label="Método de Pago"
											name="payment_method_id"
											onChange={ this.change }
											value={ this.state.form.payment_method_id }
											options={ this.state.payment_methods?.map((i: any) => {
												return {
													value: i.id,
													label: i.name
												}
											}) || [] } />
									</div>
									<div className="col-md-6">
										<Select
											color="white"
											placeholder="Seleccionar"
											label="Moneda"
											name="currency_id"
											onChange={ this.change }
											value={ this.state.form.currency_id }
											options={ this.state.currencies?.map((i: any) => {
												return {
													value: i.id,
													label: i.name
												}
											}) || [] } />
									</div>
									<div className="col-md-6">
										<Input
											type="decimal"
											value={ this.state.form.amount }
											name="amount"
											placeholder="Monto"
											label="Monto del Pago"
											onChange={ (e: any) => this.change(e) } />
									</div>
									<div className="col-md-6">
										<DatePicker
											label="Fecha del Pago"
											onChange={ (text: string) => {
												this.change({
													target: {
														value: text,
														name: 'date'
													}
												});
											} }
											maxDate={ moment().toDate() }
											value={ this.state.form.date }
										/>
									</div>
								</>
							)
						}
					</div>
	      			<div className="text-center">
						<Button type="submit">
							Guardar
						</Button>
					</div>
				</form>
			</div>
		)
	}
}

export default CreatePayment;