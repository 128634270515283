import { combineReducers } from 'redux';
import user from './user';
import header from './header';
import subheader from './subheader';
import open_menu from './open_menu';
import sidebar from './sidebar';
import access_token from './access-token';

export const reducer = combineReducers({
    user,
    header,
    subheader,
    open_menu,
    sidebar,
    access_token
});

export type RootState = ReturnType<typeof reducer>;

export default reducer;