import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors } from 'utils';
import { Table, Pagination, Tooltip, Input, DatePicker, Select, Modal } from 'components';
import { CleanIcon, AddIcon, EditIcon, TrashIcon } from 'assets/icons';
import { ReportService } from 'services';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	status: '',
	since: '',
	until: '',
	type: ''
}

class MessageReport extends React.Component<Props> {

	timer: any = undefined;

	state = {
		header: [
			'Fecha y Hora',
			'Mensaje',
			'Destinatario',
			'Caracteres',
			'Cantidad',
			'Transmisión',
			'Estatus'
		],
		form: INITIAL_STATE,
		data: [],
		page: 1,
		last_page: 1,
		visible: false,
		item: null,
		messages_count: 0
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reporte de Mensajes',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false,callback = () => {}) => {
		const res: any = await ReportService.getMessageReport({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
			since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
		});
		this.setState({
			data: res.messages.rows,
			last_page: res.messages.count,
			messages_count: res.count
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	render() {
		const { visible } = this.state;

		return (
			<div id="report-messages">
				<div className="row row-filter">
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-11 row">
								<div className="col-md-4">
									<Input
										value={ this.state.form.search }
										name="search"
										placeholder="Buscar"
										label="Buscar por mensaje o destinatario"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
								<div className="col-md-2">
									<Select
										color="white"
										placeholder="Seleccionar"
										label="Estatus"
										name="status"
										onChange={ (e: any) => this.change(e,() => this.load(true)) }
										value={ this.state.form.status }
										options={ [
											{ value: Constants.MESSAGE_STATUS.SENT.toString(), label: 'Enviados' },
											{ value: Constants.MESSAGE_STATUS.FAILED.toString(), label: 'Fallidos' },
										] } />
								</div>
								<div className="col-md-2">
									<DatePicker
										label="Desde"
										onChange={ (text: string) => {
											this.change({
												target: {
													value: text,
													name: 'since'
												}
											},() => this.load(true));
										} }
										value={ this.state.form.since }
									/>
								</div>
								<div className="col-md-2">
									<DatePicker
										label="Hasta"
										onChange={ (text: string) => {
											this.change({
												target: {
													value: text,
													name: 'until'
												}
											},() => this.load(true));
										} }
										value={ this.state.form.until }
									/>
								</div>
								<div className="col-md-2">
									<Select
										color="white"
										placeholder="Seleccionar"
										label="Transmisión"
										name="type"
										onChange={ (e: any) => this.change(e,() => this.load(true)) }
										value={ this.state.form.type }
										options={ [
											{ value: Constants.MESSAGE_TYPES.API.toString(), label: 'API' },
											{ value: Constants.MESSAGE_TYPES.CAMPAIGN.toString(), label: 'Campaña' },
										] } />
								</div>
							</div>
							<div className="col-md-1">
								{
									this.state.form != INITIAL_STATE && (
										<button className="btn btn-clean" onClick={ () => this.reset() }>
											<img src={ CleanIcon } />
										</button>
									)
								}
							</div>
						</div>
					</div>
				</div>

				<div className="text-right messages-count">
					<p><strong>Mensajes Enviados:</strong> { this.state.messages_count }</p>
				</div>

				<Table title="Reporte de Mensajes" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ moment(i.created_at).format('DD/MM/YYYY HH:mm') }</td>
								<td style={{ maxWidth: '200px' }}>{ i?.content }</td>
								<td>{ i?.to }</td>
								<td>{ i?.content?.length }</td>
								<td>{ i?.quantity }</td>
								<td>{ i?.campaign?.number ? ('Campaña Nº' + i?.campaign?.number) : 'API' }</td>
								<td>{ i?.status_text }</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(MessageReport);