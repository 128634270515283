import { Api } from 'utils';

class AdministrationMessageService {
	
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/administration-messages',params),
	}

}

export default AdministrationMessageService;