import React from 'react';
import { Input, Button } from 'components';
import { Globals, Constants, SocketEvents } from 'utils';
import { connect } from 'react-redux';
import { AuthService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router';

interface Props extends RootState, RouteComponentProps<any> {
	dispatch: any
}

class Verify extends React.Component<Props> {

	async componentDidMount() {
		if (this.props.user) {
			this.props.history.replace('/');
		}

		const res: any = await AuthService.verify({
			code: this.props.match.params.code
		});
		if (!res.user) {
			this.props.history.replace('/');
		}
		else {
			Globals.showSuccess("Su cuenta ha sido activada correctamente");
			await this.props.dispatch({
				type: 'SET_ACCESS_TOKEN',
				payload: res.access_token
			});
			await this.props.dispatch({
				type: 'SET_USER',
				payload: res.user
			});
			switch(res.user?.level_id) {
				case Constants.LEVELS.ADMIN:
					this.props.history.replace("/admin/users");
				break;

				case Constants.LEVELS.CLIENT:
					this.props.history.replace("/client/home");
				break;

				default:
					this.props.history.replace("/profile");
				break;
			}
		}

		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: '',
				onBack: null
			}
		});
	}
	
	render() {
		return null;
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Verify);