import React from 'react';
import { Input, Button, Select, Textarea, PaperClip, Table, Tooltip } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { CampaignService } from 'services';
import { connect } from 'react-redux';
import {
	CampaignUserIcon,
	CampaignListIcon,
	CampaignTitleIcon,
	TrashIcon
} from 'assets/icons';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class IndividualSMS extends React.Component<Props> {

	state = {
		form: {
			content: '',
			name: '',
			phone: ''
		},
		to: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Enviar SMS Individual',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});
	}

	submit = async () => {
		if (this.state.to.length == 0) {
			Globals.showError("Debe ingresar al menos 1 destinatario");
			return;
		}

		await CampaignService.send({
			...this.state.form,
			to: JSON.stringify(this.state.to),
			content: this.state.form.name + ': ' + this.state.form.content
		});
		Globals.showSuccess("Se ha enviado el mensaje correctamente");
		this.setState({
			form: {
				content: '',
				name: '',
				phone: ''
			},
			to: []
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	add = () => {
		const phone: any = this.state.form.phone;

		// @ts-ignore
		if (this.state.form.phone && this.state.to.indexOf(phone) == -1) {
			let to = [...this.state.to];
			// @ts-ignore
			to.push(phone);
			this.setState({
				to,
				form: {
					...this.state.form,
					phone: ''
				}
			});
		}
		else {
			this.setState({
				form: {
					...this.state.form,
					phone: ''
				}
			});
		}
	}

	delete = (e: any, index: number) => {
		e.preventDefault();
		let to = [...this.state.to];
		to.splice(index,1);
		this.setState({
			to
		});
	}
	
	render() {
		const characters = (this.state.form.name + (this.state.form.name && ': ') + this.state.form.content).length;

		return (
			<div id="modal-create-campaign">
				<div className="container-white">
					<div className="container-title">
						<div className="container-icon">
							<img src={ CampaignTitleIcon } />
						</div>
						<p>Remitente</p>
					</div>
					<p className="description">El nombre que ven tus destinatarios cuando reciben tu mensaje</p>
					<Input
						value={ this.state.form.name }
						name="name"
						placeholder="Escribir"
						onChange={ this.change } />
				</div>
				<div className="container-white">
					<div className="container-title">
						<div className="container-icon">
							<img src={ CampaignUserIcon } />
						</div>
						<p>Destinatarios</p>
					</div>
					<Input
						type="number"
						value={ this.state.form.phone }
						name="phone"
						placeholder="Escribir"
						onKeyDown={ (e: any) => {
							if (e.key == 'Enter') {
								this.add();
							}
						} } 
						onChange={ this.change } />
					<p className="note">Presione ENTER para agregar el teléfono a la lista de destinatarios</p>
					<div className="container-phones">
						{
							this.state.to.map((item: any, index: number) => {
								return (
									<div className="item-phone">
										<p>{ item }</p>
										<a href="#" onClick={ (e: any) => this.delete(e,index) }>
											<img src={ TrashIcon } />
										</a>
									</div>
								)
							})
						}
					</div>
				</div>
				<div className="container-white">
					<div className="container-title">
						<div className="container-icon">
							<img src={ CampaignListIcon } />
						</div>
						<p>Contenido del mensaje</p>
					</div>
					<Textarea
						label="Redactar el SMS"
						rows={ 4 }
						color="white"
						value={ this.state.form.name + (this.state.form.name && ': ') + this.state.form.content }
						name="content"
						placeholder="Escribir"
						onChange={ (e: any) => {
							let content = e.target.value;
							const value = this.state.form.name + (this.state.form.name && ': ');
							content = content.substr(value.length);

							this.setState({
								form: {
									...this.state.form,
									content
								}
							});
						} } />
					<div className="container-characters">
						<p className="characters-count"><strong>Caracteres:</strong> { characters }</p>
						<p className="characters-count"><strong>Cantidad de Mensajes:</strong> { Math.ceil(characters / Constants.MAX_CHARACTERS) }</p>
					</div>
				</div>
				<div className="text-center">
					<button className="btn btn-active" onClick={ () => this.submit() }>
						Enviar
					</button>
				</div>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(IndividualSMS);