import { Api } from 'utils';

class ReportService {
	
	static getMessageReport = async (params?: any) => Api.createResponse('client/reports/message-report',params);
	static getPaymentReport = async (params?: any) => Api.createResponse('client/reports/payments-report',params);

	static admin = {
		getMessageReport: async (params?: any) => Api.createResponse('admin/reports/message-report',params),
		getPaymentReport: async (params?: any) => Api.createResponse('admin/reports/payments-report',params),
		createPayment: async (params?: any) => Api.createResponse('admin/reports/create-payment',params),
		getPaymentMethods: async (params?: any) => Api.createResponse('admin/reports/payment-methods',params),
		deletePayment: async (params?: any) => Api.createResponse('admin/reports/delete-payment',params),
		loadPendingPayments: async (params?: any) => Api.createResponse('admin/reports/load-pending-payments',params),
	}

}

export default ReportService;