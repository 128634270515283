import { Api } from 'utils';

class CampaignService {
	
	static get = async (params?: any) => Api.createResponse('client/campaign',params);
	static send = async (params?: any) => Api.createResponse('client/campaign/send',params);
	static readExcel = async (params?: any) => Api.createResponse('client/campaign/read-excel',params);
	static getFolders = async (params?: any) => Api.createResponse('client/campaign/folders',params);
	static loadFolder = async (params?: any) => Api.createResponse('client/campaign/load-folder',params);
	static sendIndividual = async (params?: any) => Api.createResponse('client/campaign/send-individual',params);

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/campaign',params),
	}

}

export default CampaignService;