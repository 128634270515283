import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { 
	ArrowLeft, 
	ProfileIcon,
	LockIcon,
	LogoutIcon,
	LogoutGray,
	UserSettings,
	MenuIcon,
	UserIcon,
	CaretIcon
} from 'assets/icons';
import { Logo, WhiteImage } from 'assets/img';

interface Props {
	className?: string;
	toggleSidebar?: any;
	toggleResponsive?: any;
}

const logout = (dispatch: any,history: any) => {
	Globals.confirm('¿Desea cerrar sesión?',() => {
		dispatch({
			type: 'SET_USER',
			payload: null
		});
		dispatch({
			type: 'SET_ACCESS_TOKEN',
			payload: null
		});
		history.replace('/login');
		Globals.quitLoading();
	});
}

const Header = (props: Props) => {
	const header = useSelector((state: RootState) => state.header);
	const subheader = useSelector((state: RootState) => state.subheader);
	const user = useSelector((state: RootState) => state.user);
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const [open,setOpen] = useState(false);

	return (
		<div id="header" className={ props.className }>
			<div className="container-blue-top">
				<button className="navbar-toggler responsive" type="button" onClick={ () => {
					props.toggleResponsive();
				} }>
				    <img src={ MenuIcon } />
				</button>
				<div className="container-logo">
					<Link to="/">
						<img src={ Logo } />
					</Link>
				</div>
				<div className="container-back-top">
					{/*<button className="navbar-toggler" type="button" onClick={ () => {
						props.toggleSidebar();
					} }>
					    <img src={ MenuIcon } />
					</button>*/}
					{
						header.onBack && (
							<div className="arrow-back" onClick={ header.onBack }>
								<img src={ ArrowLeft } />
							</div>
						)
					}
					<div className="container-titles">
						<h3 className="title-top">{ header.title }</h3>
						<h4 className="subtitle-top">{ subheader.title }</h4>
					</div>	
				</div>
				<div className="container-buttons-top">
					<div className="container-buttons-web">
						<button className="focusable-container" onBlur={ () => setOpen(false) }>
							{
								user && (
									<div className="container-user" onClick={ () => setOpen(!open) }>
										<img src={ UserIcon } />
										<p>Bienvenido, { user?.name }</p>
										<img src={ CaretIcon } className="caret" />
									</div>
								)
							}
							{
								open && (
									<ul>
										<li onClick={ () => { setOpen(false); history.replace('/profile') } }>
											<img src={ ProfileIcon } />
											Perfil
										</li>
										<li onClick={ () => { setOpen(false); logout(dispatch,history) } }>
											<img src={ LogoutIcon } />
											Cerrar sesión
										</li>
									</ul>
								)
							}		
						</button>	
					</div>
				</div>
			</div>		
		</div>
	)	
}

export default Header;