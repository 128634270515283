import Globals from './globals';

class Error {
	
	default = (err: any) => {
		if (err.response && err.response.status === 422) {
			Globals.showError(err.response.data.message || err.response.data.error);
         	return;
		}
		else {
			console.log(err);
			Globals.showError();
		}
	}
}

export default new Error();