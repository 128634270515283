import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { Constants } from 'utils';
import { Redirect } from 'react-router-dom';

const Home = () => {
	const user = useSelector((state: RootState) => state.user);

	if (!user) {
		return <Redirect to="/login" />
	}
	else {
		switch(user?.level_id) {
			case Constants.LEVELS.ADMIN:
				return <Redirect to="/admin/users" />
			break;

			case Constants.LEVELS.CLIENT:
				return <Redirect to="/client/home" />
			break;

			default:
				return <Redirect to="/profile" />
			break;
		}
	}
}

export default Home;