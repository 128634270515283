import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors } from 'utils';
import { Table, Pagination, Tooltip, Input, DatePicker, Select, Modal } from 'components';
import { CleanIcon, AddIcon, EditIcon, TrashIcon } from 'assets/icons';
import { ReportService } from 'services';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	year: moment().format('YYYY')
}

class PaymentReport extends React.Component<Props> {

	timer: any = undefined;

	state = {
		header: [
			'Fecha Vencimiento',
			'Plan',
			'Fecha de pago',
			'Monto',
			'Método de Pago'
		],
		form: INITIAL_STATE,
		data: [],
		page: 1,
		last_page: 1,
		years: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Reporte de Pagos',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		let years: any = [...this.state.years];

		for (let i = parseInt(moment().format('YYYY')); i > 2000; i--) {
			years.push({
				value: i,
				label: i
			});
		}

		this.setState({
			years
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false,callback = () => {}) => {
		const res: any = await ReportService.getPaymentReport({
			withoutLoading,
			page: this.state.page,
			...this.state.form
		});
		this.setState({
			data: res.payments.rows,
			last_page: res.payments.count
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	render() {
		return (
			<div id="report-payments">
				<div className="row row-filter">
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-8 row">
								<div className="col-md-6">
									<Select
										color="white"
										placeholder="Seleccionar"
										label="Año"
										name="year"
										onChange={ (e: any) => this.change(e,() => this.load(true)) }
										value={ this.state.form.year }
										options={ this.state.years } />
								</div>
							</div>
							<div className="col-md-1">
								{
									this.state.form != INITIAL_STATE && (
										<button className="btn btn-clean" onClick={ () => this.reset() }>
											<img src={ CleanIcon } />
										</button>
									)
								}
							</div>
						</div>
					</div>
				</div>

				<Table title="Reporte de Pagos" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ moment(i.plan_user?.expiration_date).format('DD/MM/YYYY') }</td>
								<td>{ i.plan_user?.plan?.name || '-' }</td>
								<td>{ moment(i.created_at).format('DD/MM/YYYY') }</td>
								<td>{ Globals.formatMiles(i.amount,true,i.currency?.symbol) }</td>
								<td>{ i.payment_method?.name || '-' }</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(PaymentReport);