import { Api } from 'utils';

class UserService {

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/users',params),
		update: async (params?: any) => Api.createResponse('admin/users/update',params),
		delete: async (params?: any) => Api.createResponse('admin/users/delete',params),
		deletePlanUser: async (params?: any) => Api.createResponse('admin/users/delete-plan-user',params),
	}

}

export default UserService;