import React from 'react';
import { Input, Button, Select, PaperClip, Tooltip, Table } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { ContactService } from 'services';
import { UserListIcon, CampaignUserIcon, CampaignTitleIcon, CampaignListIcon, TrashIcon } from 'assets/icons';

interface Props {
	onClose?: any;
}

class ModalCreateContact extends React.Component<Props> {

	state = {
		form: {
			name: ''
		},
		contacts: [],
		header: [
			'Nombre',
			'Teléfono',
			'Acción'
		]
	}

	submit = async () => {
		if (this.state.contacts.length == 0) {
			Globals.showError("Debe ingresar por lo menos 1 contacto");
			return;
		}

		await ContactService.create({
			...this.state.form,
			contacts: JSON.stringify(this.state.contacts)
		});
		this.props.onClose();
		Globals.showSuccess("Se ha subido correctamente los contactos");
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	readExcel = async (file: any) => {
		const res: any = await ContactService.readExcel({
			file: file.value,
			hasFile: true
		});
		this.setState({
			contacts: res.contacts || []
		});
	}

	delete = async (e: any, index: number) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar el contacto?',async () => {
			let contacts = [...this.state.contacts];
			contacts.splice(index,1);
			this.setState({
				contacts
			});
		});
	}
	
	render() {
		return (
			<div id="modal-create-contact">
				<div className="container-white">
					<div className="container-title">
						<div className="container-icon">
							<img src={ CampaignTitleIcon } />
						</div>
						<p>Nombre de la carpeta</p>
					</div>
					<Input
						value={ this.state.form.name }
						name="name"
						placeholder="Escribir"
						onChange={ this.change } />
				</div>
				<div className="container-white">
					<div className="container-title">
						<div className="container-icon">
							<img src={ CampaignUserIcon } />
						</div>
						<p>Destinatarios</p>
					</div>
					<PaperClip
						successText={ `Subir contactos (Excel)` }
						pendingText={ `Subir contactos (Excel)` }
						icon={ UserListIcon }
						formats={ ['xls','xlsx'] }
						formatError="El archivo debe ser un excel válido"
						onChange={ (e: any) => {
							this.readExcel(e.target);
						} } />
					<p className="note">Nota: el sistema leera los nombres de la columna A y los números de teléfono de la columna B</p>
				</div>
				{
					this.state.contacts.length > 0 && (
						<div className="container-white">
							<div className="container-title">
								<div className="container-icon">
									<img src={ CampaignListIcon } />
								</div>
								<p>Contactos</p>
							</div>
							<p className="contacts-count"><span>{ this.state.contacts.length } Contacto(s)</span></p>
							<Table title="Contactos" data={ this.state.contacts.length } header={ this.state.header } rightAlign={ [2] }>
								{ this.state.contacts.map((i: any,index: number) => {
									return (
										<tr key={ index }>
											<td>{ i.name }</td>
											<td>{ i.phone }</td>
											<td style={{ 'whiteSpace': 'nowrap', textAlign: 'right' }}>
												<Tooltip title="Eliminar">
													<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
														<img src={ TrashIcon } />
													</a>
												</Tooltip>
											</td>
										</tr>
									)
								}) }
							</Table>
						</div>
					)
				}
				<div className="text-center">
					<button className="btn btn-active" onClick={ () => this.submit() }>
						Enviar
					</button>
				</div>
			</div>
		)
	}
}

export default ModalCreateContact;