import { Api } from 'utils';

class ContactService {

	static get = async (params?: any) => Api.createResponse('client/contacts',params);
	static create = async (params?: any) => Api.createResponse('client/contacts/create',params);
	static delete = async (params?: any) => Api.createResponse('client/contacts/delete',params);
	static readExcel = async (params?: any) => Api.createResponse('client/contacts/read-excel',params);

}

export default ContactService;