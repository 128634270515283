import { axios, Error, Globals } from 'utils';
import { AxiosError, AxiosResponse } from 'axios';
import { store } from 'store';

class Api {
	
	createResponse = (uri: string,params?: any) => {	
		if (!params) {
			params = {};
		}

		axios.defaults.headers.common = {'Authorization': `Bearer ${ store.getState().access_token }`}

		return new Promise((resolve,reject) => {
			if (!params.withoutLoading) {
				Globals.setLoading();
			}
			axios[params.hasFile ? 'upload' : 'post'](uri,params)
				.then((res: AxiosResponse) => {
					if (!params.withoutLoading) {
						Globals.quitLoading();
					}
					resolve(res.data);					
				})
				.catch((err: AxiosError) => {
					if (!params.withoutLoading) {
						Globals.quitLoading();
					}
					Error.default(err);
					reject(err);
				});
		});
	}
}

export default new Api();