import React from 'react';
import { Input, Button, Select } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { PlanService } from 'services';

interface Props {
	onClose?: any;
}

class SelectPlan extends React.Component<Props> {

	state = {
		plans: []
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await PlanService.getPlanList();
		this.setState({
			plans: res.plans || []
		});
	}

	setActive = (item: any, index: number) => {
		let plans: any = [...this.state.plans].map((item: any) => {
			item.active = false;
			return item;
		});
		plans[index].active = !plans[index].active;
		this.setState({
			plans
		});
	}

	submit = async () => {
		const plan: any = this.state.plans.find((item: any) => item.active);

		await PlanService.save({
			plan_id: plan?.id
		});
		Globals.showSuccess("Se ha contratado el plan");
		this.props.onClose();
	}
	
	render() {
		const plan: any = this.state.plans.find((item: any) => item.active);

		return (
			<div id="modal-view-plans">
				<div className="row">
					{
						this.state.plans.map((item: any,index: number) => {
							return (
								<div className="col-md-4">
									<div className={ `item-plan ${ item.active ? 'active' : '' }` } onClick={ () => this.setActive(item,index) }>
										<div className="row">
											<div className="col-md-7">
												<p className="name">{ item?.name }</p>
												<p className="messages">{ item?.quantity } SMS</p>
											</div>
											<div className="col-md-5 text-right">
												<p className="price">{ Globals.formatMiles(item?.price) }</p>
												<p className="without-iva">(Sin IVA)</p>
											</div>
										</div>
										<p className="small"><strong>Remitentes:</strong> Movistar, Movilnet, Digitel</p>
										<p className="small no-margin"><strong>Restricciones:</strong> Prohibidos contenidos políticos, loterías, apuestas, hípica, bebidas alcohólicas, chats personales y lenguaje obsceno.</p>
									</div>
								</div>
							)
						})
					}
				</div>
				{
					plan != null && (
						<div className="text-center">
							<Button type="submit" onClick={ () => this.submit() }>
								Continuar
							</Button>
						</div>
					)
				}
			</div>
		)
	}
}

export default SelectPlan;