import { Api } from 'utils';

class HomeService {
	
	static get = async (params?: any) => Api.createResponse('client/home',params);

	static admin = {
		get: async (params?: any) => Api.createResponse('admin/home',params),
	}

}

export default HomeService;